import React, { useState } from 'react'
import Dropdown from 'react-dropdown'

const ReactDropdown = ({
  options,
  selectedValue,
  onChange,
  placeholder,
  className,
  label,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleOpen = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const handleSelect = (option) => {
    onChange(option);
    setDropdownOpen(false);
  }

  return (
    <div>
      {label && <label>{label}</label>}
      <Dropdown
        className={`${className} ${dropdownOpen ? 'open' : ''}`}
        options={options}
        value={selectedValue}
        onChange={handleSelect}
        placeholder={placeholder}
      />
    </div>
  )
}

export default ReactDropdown

// import React, { useState, useEffect, useRef } from 'react'
// import Dropdown from 'react-dropdown'

// const ReactDropdown = ({
//   options,
//   value,
//   onChange,
//   placeholder,
//   className,
//   label,
// }) => {

//   const [dropdownopen, setDropdownOpen] = useState(false)

//   const dropdownRef = useRef(null);
//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => {
//     const handleClick = (e) => {
//       if (!dropdownRef.current.contains(e.target)) {
//         console.log('23');
//         console.log('ad', dropdownRef.current.contains(e.target));
//         setIsOpen(!isOpen);
//         console.log('0p', !isOpen);
//       }
//     };

//     document.addEventListener("mousedown", handleClick);
//     return () => {
//       document.removeEventListener("mousedown", handleClick);
//     };

//   }, []);

//   //let menuRef = useRef();

//   // const toggleOpen = () => {
//   //   setDropdownOpen(!dropdownopen)
//   // }

//   // useEffect(() => {
//   //   let handler = (e) => {
//   //     if(menuRef.current.contains(e.target) || !menuRef.current.contains(e.target)){
//   //       setDropdownOpen(!dropdownopen);
//   //     }
//   //   };
//   //   document.addEventListener('mousedown', handler);

//   //   // return() => {
//   //   //   document.removeEventListener("mousedown", handler);
//   //   // }
//   // })

//   return (
//     <div ref={dropdownRef}>
//       {label && <label>{label}</label>}
//       <Dropdown
//         // className={`${className}`}
//         options={options}
//         value={value}
//      //   onChange={onChange}
//         placeholder={placeholder}
//         isOpen={isOpen}
//         menuIsOpen={!isOpen}
//         controlClassName={isOpen}
//        // onClose={() => setIsOpen(false)}
//         is-open={isOpen}
//         // onClick={toggleOpen}
//         onChange={this._onSelect}
//      //   value={defaultOption}
//     //    placeholder="Select an option"
//       />
//     </div>
//   )
// }

// export default ReactDropdown
